<template>
     <CForm ref="form" class="needs-validation"   >
        <CInput
            placeholder="Rol Adı"
            type="text"
            autocomplete="text"
            v-model="data.name"
            required
        >
            <template #prepend-content>
                <CIcon name="cil-envelope-closed"/>
            </template>
        </CInput>
        <CInput
            placeholder="Güvenlik Seviyesi"
            type="text"
            autocomplete="text"
            v-model="data.securityLevel"
            required
        >
            <template #prepend-content>
                <CIcon name="cil-envelope-closed"/>
            </template>
        </CInput>
    </CForm>
</template>

<script>
    export default{
        name: "RoleForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>